import { IntroContainer, IntroName, JobTitle, Slider } from "./Intro.styled";

const Intro = () => {
  return (
    <>
      <Slider />
      <IntroContainer>
        <IntroName>sandy pranjic</IntroName>
        <JobTitle>software engineer</JobTitle>
      </IntroContainer>
    </>
  );
};

export default Intro;