import { css } from 'styled-components';

export const mediaQuery = {
  smallLaptop: '@media (max-width: 1000px)',
  tablet: '@media (max-width: 810px)',
  mobile: '@media (max-width: 590px)',
}

export const colors = {
  dark: '#121212',
  medium: '#555555',
  background: '#F9F8F7',
  border: '#D9D9D9',
};

export const typography = {
  header: css`
    font-family: Kanit;
    font-size: 100px;
    font-weight: 500;
    line-height: 100px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.dark};
  `,
  accentHeader: css`
    font-family: NewYork;
    font-size: 100px;
    font-weight: 400;
    line-height: 100px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.dark};
  `,
  subHeader: css`
    font-family: Kanit;
    font-size: 50px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.dark};
  `,
  accentSubHeader: css`
    font-family: NewYork;
    font-size: 45px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.dark};
  `,
  bodyOne: css`
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.medium};
  `,
};

export const theme = {
  colors,
  typography,
  mediaQuery,
};
