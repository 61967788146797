import styled from "styled-components";
import { theme } from "../../theme/fonts/theme";

export const AboutHeader = styled.h3`
  ${theme.typography.subHeader}
  width: 40%;
  text-transform: uppercase;
  span {
    ${theme.typography.accentSubHeader}
    display: inline-block;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 95%;
      left: 2.5%;
      height: 1px;
      background-color: ${theme.colors.dark};
    }
  }
  ${theme.mediaQuery.tablet} {
    width: 100%;
    text-align: center;
    margin: 50px 0;
  }
  ${theme.mediaQuery.tablet} {
    font-size: 30px;
    line-height: 30px;
    span {
      font-size: 30px;
      line-height: 30px;
    }
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 100px;
  ${theme.mediaQuery.smallLaptop} {
    padding: 100px 50px;
  }
  ${theme.mediaQuery.tablet} {
    flex-direction: column;
    padding: 50px;
  }
  ${theme.mediaQuery.mobile} {
    padding: 25px;
  }
`;

export const BioContainer = styled.div`
  width: 40%;
  ${theme.mediaQuery.smallLaptop} {
    width: 55%;
  }
  ${theme.mediaQuery.tablet} {
    width: 100%;
  }
`;

export const Biography = styled.p`
  ${theme.typography.bodyOne}
  p {
    &:first-of-type {
      text-indent: 25px;
    }
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;