import { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Experience from "./components/Experience/Experience";
import Footer from "./components/Footer/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Intro from "./components/Intro/Intro";

const AppContainer = styled.div`
  min-height: 100vh;
`;

const App = () => {
  const [showIntro, setShowIntro] = useState(true);

  const hideIntro = () => {
    setTimeout(() => {
      setShowIntro(false);
    }, 5000);
  };

  useEffect(() => {
    AOS.init({
      delay: 200,
      duration: 500,
      mirror: false,
    });

    hideIntro();
  }, []);

  return (
    <>
      {showIntro && <Intro />}
      <AppContainer>
        <Header />
        <About />
        <Experience />
        <Footer />
      </AppContainer>
    </>
  );
}

export default App;
