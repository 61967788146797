import styled, { keyframes } from "styled-components";
import { theme } from "../../theme/fonts/theme";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const HeaderFadeIn = styled.div`
  opacity: 0;
  animation-duration: 2s;
  animation-name: ${fadeIn};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 5s;
`;

export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  padding: 0 100px;
  background-color: ${theme.colors.background};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQuery.smallLaptop} {
    padding: 0 50px;
  }
`;

export const Name = styled.h2`
  ${theme.typography.bodyOne}
  font-size: 12px;
  cursor: pointer;
`;

export const Contact = styled.a`
  display: block;
  ${theme.typography.bodyOne}
  font-size: 12px;
`;

export const HeaderContainer = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  ${theme.mediaQuery.tablet} {
    padding: 50px;
  }
`;

export const Heading = styled.h1`
  ${theme.typography.accentHeader}
  ${theme.mediaQuery.smallLaptop} {
    font-size: 70px;
    line-height: 70px;
  }
  ${theme.mediaQuery.mobile} {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const SubHeader = styled.h2`
  ${theme.typography.bodyOne}
  width: 320px;
  margin: 50px 0 0 50px;
  ${theme.mediaQuery.tablet} {
    margin: 25px 0;
  }
`;

export const HeaderImage = styled.img`
  width: 30vw;
  margin: 150px auto 0 auto;
  ${theme.mediaQuery.tablet} {
    width: 40vw;
    margin: 50px auto 0 auto;
  }
  ${theme.mediaQuery.tablet} {
    margin: 0 auto;
  }
`;

export const ScrollIndicator = styled.div`
  position: absolute;
  top: 400px;
  left: 100px;
  svg {
    height: 300px;
  }
  ${theme.mediaQuery.smallLaptop} {
    top: 350px;
    svg {
      height: 200px;
    }
  }
  ${theme.mediaQuery.tablet} {
    left: 50px;
    top: 250px;
    svg {
      height: 100px;
    }
  }
`;

