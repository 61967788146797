import styled, { css } from "styled-components";
import { theme } from "../../theme/fonts/theme";

export const FooterContainer = styled.footer`
  padding: 100px;
  background-color: ${theme.colors.dark};
  ${theme.mediaQuery.smallLaptop} {
    padding: 100px 50px;
  }
  ${theme.mediaQuery.tablet} {
    padding: 50px;
  }
  ${theme.mediaQuery.mobile} {
    padding: 25px;
  }
`;

const sharedHeading = css`
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 130px;
  line-height: 120px;
  ${theme.mediaQuery.smallLaptop} {
    font-size: 80px;
    line-height: 70px;
  }
`;

export const FooterHeading = styled.h3`
  ${theme.typography.header}
  ${sharedHeading}
  padding-bottom: 50px;
  span {
    ${theme.typography.accentHeader}
    ${sharedHeading}
  }
  ${theme.mediaQuery.tablet} {
    font-size: 40px;
    line-height: 40px;
    span {
      font-size: 40px;
      line-height: 40px;
    }
  }
  ${theme.mediaQuery.mobile} {
    padding-bottom: 25px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  padding-top: 50px;
  border-top: 1px solid white;
  ${theme.mediaQuery.mobile} {
    padding-top: 25px;
    flex-direction: column;
  }
`;

export const EmailLink = styled.a`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid white;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  span {
    ${theme.typography.bodyOne}
    display: block;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
  }
  ${theme.mediaQuery.mobile} {
    height: 50px;
    border-radius: 0;
    width: 100%;
    margin-top: 25px;
  }
`;

export const FooterSubHeading = styled.h5`
  ${theme.typography.bodyOne}
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  padding-bottom: 25px;
`;

export const FooterSection = styled.div<{ isSocialLinks?: boolean; }>`
  width: ${props => props.isSocialLinks ? '20%' : '45%'};
  display: flex;
  flex-direction: column;
  ${theme.mediaQuery.tablet} {
    width: ${props => props.isSocialLinks ? '25%' : '55%'};
  }
  ${theme.mediaQuery.mobile} {
    width: 100%;
    margin-top: 25px;
  }
`;

export const Socials = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const SocialLink = styled.a`
  ${theme.typography.bodyOne}
  text-transform: uppercase;
  color: white;
`;

export const ReachOut = styled.p`
  ${theme.typography.bodyOne}
  color: white;
  a {
    color: white;
  }
`;
