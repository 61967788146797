import styled from "styled-components";
import { theme } from "../../theme/fonts/theme";

export const ExperienceContainer = styled.div`
  padding: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.mediaQuery.smallLaptop} {
    padding: 50px;
  }
  ${theme.mediaQuery.tablet} {
    flex-direction: column-reverse;
  }
  ${theme.mediaQuery.mobile} {
    padding: 25px;
  }
`;

export const ExperienceHeader = styled.h2`
  ${theme.typography.header}
  white-space: nowrap;
  text-align: center;
  font-size: 80px;
  line-height: 80px;
  margin-top: 100px;
  text-transform: uppercase;
  span {
    ${theme.typography.accentHeader}
    font-size: 80px;
    line-height: 80px;
  }
  ${theme.mediaQuery.smallLaptop} {
    font-size: 50px;
    line-height: 50px;
    span {
      font-size: 50px;
      line-height: 50px;
    }
  }
  ${theme.mediaQuery.tablet} {
    margin-top: 50px;
    font-size: 40px;
    line-height: 40px;
    white-space: wrap;
    span {
      font-size: 40px;
      line-height: 40px;
    }
  }
  ${theme.mediaQuery.mobile} {
    margin-top: 25px;
  }
`;

export const ResumeItem = styled.li`
  ${theme.typography.bodyOne}
  padding: 0 25px 50px 25px;
  border-bottom: 1px solid ${theme.colors.border};
  &:not(:first-of-type) {
    padding-top: 25px;
  }
  ${theme.mediaQuery.tablet} {
    padding: 0 0 50px 0;
  }
`;

export const ItemHeader = styled.h4`
  color: ${theme.colors.dark};
  font-size: 20px;
  font-weight: 500;
`;

export const Item = styled.span`
  display: block;
`;

export const Date = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  color: ${theme.colors.dark};
`;

export const Resume = styled.ul`
  display: flex;
  flex-direction: column;
  width: 60%;
  ${theme.mediaQuery.tablet} {
    width: 100%;
  }
`;

export const PillboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
`;


export const Pillbox = styled.span<{ index: number }>`
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid ${theme.colors.dark};
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  color: ${theme.colors.dark};
`;

export const SparkleAccentContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  ${theme.mediaQuery.smallLaptop} {
    width: 200px;
    height: 200px;
  }
  ${theme.mediaQuery.tablet} {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  ${theme.mediaQuery.mobile} {
    margin-top: 25px;
  }
`;

export const SparkleAccent = styled.svg<{
  size: string;
  position: {
    left: string;
    top: string
  };
}>`
  height: ${props => props.size};
  width: ${props => props.size};
  position: absolute;
  left: ${props => props.position.left};
  top: ${props => props.position.top};
  ${theme.mediaQuery.smallLaptop} {
    max-width: 100px;
    max-height: 100px;
  }
  ${theme.mediaQuery.tablet} {
    width: 50px;
    height: 50px;
    position: static;
  }
`;
