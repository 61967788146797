import { HeaderContainer, HeaderImage, Heading, ScrollIndicator, SubHeader, TopBar, Name, Contact} from "./Header.styled";
import { ReactComponent as ScrollImage } from '../../assets/scroll.svg';
import '../../assets/headerImage.JPG';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Header = () => {
  const subHeader = <>I'm a software engineer based in Toronto.<br />I specialize in React and TypeScript.</>;

  return (
    <>
      <TopBar>
        <Name onClick={scrollToTop}>sandy pranjic</Name>
        <Contact href="mailto:sandy@sandy.codes">contact</Contact>
      </TopBar>
      <HeaderContainer>
        <Heading>Software Engineer</Heading>
        <SubHeader>{subHeader}</SubHeader>
        <HeaderImage src={require('../../assets/headerImage.JPG')} />
        <ScrollIndicator>
          <ScrollImage />
        </ScrollIndicator>
      </HeaderContainer>
    </>
  );
};

export default Header;
