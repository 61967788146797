import { AboutContainer, Biography, BioContainer, AboutHeader } from "./About.styled";
import BorderAccent from "../SectionHeader/SectionHeader";

const bio = (
  <>
    <p>Hello, I'm Sandy. I'm a software engineer splitting my time between Toronto and travelling the world. I have over three years of professional software development experience, and twenty years of coding as a hobby. I started learning how to code as a nerdy eight-year-old kid wanting to customize my Neopets pages, and haven't stopped since.</p>
    <p>My expertise lies on the frontend, primarily via React and TypeScript. I value writing human-readable code, and building interfaces that equally weigh beautiful design with accessibility for all.</p>
    <p>I'm also a visual artist. In 2017, I completed a Bachelor of Fine Arts at OCAD University, and spent the first few years after graduation working as a photographer at the Art Gallery of Ontario. In my spare time I still paint and draw and start all sorts of creative projects around my apartment.</p>
    <p>I hope to someday use my knowledge as a developer for social good. I'm particularly interested in building software for environmental initiatives.</p>
  </>
);

const aboutHeader = (
  <>
    I use my background in <span>visual arts</span> to build <span>creative</span> tech solutions.
  </>
);

const About = () => {
  return (
    <>
      <BorderAccent
        orderNumber="01"
        sectionHeading="About me"
      />
      <AboutContainer data-aos="fade-in">
        <AboutHeader>
          {aboutHeader}
        </AboutHeader>
        <BioContainer>
          <Biography>{bio}</Biography>
        </BioContainer>
      </AboutContainer>
    </>
  );
};

export default About;
