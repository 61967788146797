import styled from "styled-components";
import { theme } from "../../theme/fonts/theme";

export const BorderAccentContainer = styled.div`
  width: 100%;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${theme.mediaQuery.smallLaptop} {
    padding: 0 50px;
  }
  ${theme.mediaQuery.mobile} {
    padding: 0 25px;
  }
`;

const SectionHeading = styled.h4`
  ${theme.typography.bodyOne}
  font-size: 12px;
  position: absolute;
  right: 100px;
  top: 0;
  text-transform: uppercase;
`;

export const Border = styled.div`
  width: calc(100% - 60px);
  height: 1px;
  background-color: ${theme.colors.border};
`;

const OrderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
`;

const Square = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid ${theme.colors.border};
  transform: rotate(45deg);
  background-color: ${theme.colors.background};
  position: absolute;
  z-index: -1;
`;

const OrderNumber = styled.span`
  ${theme.typography.bodyOne}
  display: block;
  font-size: 12px;
`;

const BorderAccent = ({
  orderNumber,
  sectionHeading
}: {
  orderNumber: string;
  sectionHeading: string;
}) => {
  return (
    <BorderAccentContainer>
      <OrderContainer>
        <OrderNumber>{orderNumber}</OrderNumber>
        <Square />
      </OrderContainer>
      <SectionHeading>{sectionHeading}</SectionHeading>
      <Border />
    </BorderAccentContainer>
  );
};

export default BorderAccent;