import styled, { css, keyframes } from "styled-components";
import { theme } from "../../theme/fonts/theme";

const fadeIn = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    background-color: ${theme.colors.dark};
  }
  80% {
    opacity: 1;
    background-color: white;
  }
  100% {
    opacity: 0;
    background-color: white;
  }
`;

export const IntroContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.dark};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 5s;
  animation-name: ${fadeIn};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const slider = keyframes`
  0% {
    max-width: 100vw;
  }
  100% {
    max-width: 0;
  }
`;

export const Slider = styled.div`
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${theme.colors.dark};
  animation-duration: 2s;
  animation-name: ${slider};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 200;
`;

const introText = css`
  font-size: 28px;
  line-height: 28px;
  color: white;
`;

export const IntroName = styled.h2`
  font-family: 'Kanit';
  ${introText}
  margin-right: 10px;
  font-weight: 200;
`;

export const JobTitle = styled.h2`
  font-family: 'NewYork';
  ${introText}
  margin-left: 10px;
`;