import { ExperienceContainer, Resume, ResumeItem, ItemHeader, Item, Date, Pillbox, ExperienceHeader, PillboxContainer, SparkleAccent, SparkleAccentContainer } from "./Experience.styled";
import SectionHeader from '../SectionHeader/SectionHeader';
import { ReactComponent as Sparkle } from '../../assets/sparkle.svg';

const resume = [
  {
    company: 'useRoam.io',
    role: 'Co-Founder & Software Engineer',
    url: 'https://www.useroam.io',
    stack: ['React', 'TypeScript', 'Styled-Components', 'Hooks', 'Tailwind', 'Supabase'],
    description: "For the past several months, myself and my two co-founders have been building Roam, a business management platform for digital creatives. Our first tool, the Fee Finder, helps creatives get paid their worth by estimating accurate project fees based off of various metrics, including the living wage in their location.",
    date: 'May 2023 - Present',
  },
  {
    company: 'Veed.io',
    role: 'Software Engineer',
    url: 'https://www.veed.io',
    stack: ['React', 'TypeScript', 'Styled-Components', 'NodeJS', 'Jest', 'Playwright', 'Redux', 'Hooks', 'CSS', 'NextJS', 'FFMPEG', 'Stripe', 'Tailwind'],
    description: "I've spent the last two-and-a-half years building online video editing software at Veed.io, where I was one of the first engineers to join. As an early joiner, I've contributed all across the codebase: from building a drag-and-drop library in the editor, creating software to convert video file formats, to now working on the Payments Infrastructure team where I've built a shared package to handle all of our payments.",
    date: 'March 2021 - July 2023',
  },
  {
    company: 'Shopkick',
    role: 'Software Engineer',
    url: 'https://www.shopkick.com',
    stack: ['React', 'JavaScript', 'SCSS', 'HTML5'],
    date: 'May 2020 - March 2021',
    description: 'Prior to Veed, I worked at Shopkick, a Silicon Valley-based startup. During my time there, I built an internal dashboard to manage content in the mobile app. I was the main engineer working on the frontend, and the product I created contributed to an over 50% efficiency increase for the Operations team.',
  },
];

const sparkleAccents = [
  {
    size: '150px',
    position: {
      left: '0',
      top: '0',
    },
  },
  {
    size: '50px',
    position: {
      left: '100%',
      top: '50%',
    },
  },
  {
    size: '30px',
    position: {
      left: '50%',
      top: '100%',
    },
  },
];

const Experience = () => {
  return (
    <div data-aos="fade-in">
      <SectionHeader
        orderNumber="02"
        sectionHeading="Work Experience"
      />
      <ExperienceHeader>
        Where I've <span>Contributed</span>
      </ExperienceHeader>
      <ExperienceContainer>
        <SparkleAccentContainer>
          {sparkleAccents.map((sparkle) => (
            <SparkleAccent
              as={Sparkle}
              size={sparkle.size}
              position={sparkle.position}
            />
          ))}
        </SparkleAccentContainer>
          <Resume>
            {resume.map((it) =>
              <ResumeItem key={it.company}>
                <ItemHeader>
                  <a
                    href={it.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {it.company} / {it.role}
                  </a>
                </ItemHeader>
                <Date>{it.date}</Date>
                <PillboxContainer>
                  {it.stack.map((st, index) =>
                    <Pillbox index={index}>{st}</Pillbox>
                  )}
                </PillboxContainer>
                <Item>{it?.description}</Item>
              </ResumeItem>
            )}
        </Resume>
      </ExperienceContainer>
    </div>
  );
};

export default Experience;
