import { EmailLink, FooterContainer, FooterHeading, FooterSection, FooterSubHeading, TextContainer, Socials, SocialLink, ReachOut } from "./Footer.styled";

const socials = [
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/sandypranjic/',
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/sandy.iris/',
  },
  {
    label: 'Github',
    url: 'https://github.com/sandypranjic',
  },
];

const reachOutText = (
  <>
    I'm always open to a virtual coffee — especially regarding freelance work or with underrepresented folks in tech looking for early career guidance. Feel free to get in touch via <a href="mailto:sandy@sandy.codes">sandy@sandy.codes</a>.
  </>
);

const Footer = () => {
  return (
    <FooterContainer data-aos="fade-in">
      <FooterHeading>Let's <span>Connect</span></FooterHeading>
      <TextContainer>
        <FooterSection isSocialLinks={true}>
          <FooterSubHeading>Find Me Here</FooterSubHeading>
          <Socials>
            {socials.map((link) => 
              <SocialLink target="_blank" href={link.url}>{link.label}</SocialLink>
            )}
          </Socials>
        </FooterSection>
        <FooterSection>
          <FooterSubHeading>Reach Out</FooterSubHeading>
          <ReachOut>{reachOutText}</ReachOut>
        </FooterSection>
        <EmailLink href="mailto:sandy@sandy.codes"><span>Email Me</span></EmailLink>
      </TextContainer>
    </FooterContainer>
  );
};

export default Footer;
